// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconFile from "../../../../../styleguide/icons/IconFile.res.js";
import * as IconView from "../../../../../styleguide/icons/IconView.res.js";
import * as IconDelete from "../../../../../styleguide/icons/IconDelete.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ResourceItem from "../../../../../models/ResourceItem.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationBrochureEditFormScss from "./LocationBrochureEditForm.scss";

var css = LocationBrochureEditFormScss;

function LocationBrochurePdf$DocumentRow(props) {
  var onDelete = props.onDelete;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconFile.make, {
                              size: "LG",
                              color: "DarkGray"
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.filename,
                              className: css.fileNameText
                            })
                      ],
                      className: css.flexContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("p", {
                              children: ResourceItem.$$Document.sizeText(props.byteSize),
                              className: css.fileSizeText
                            }),
                        JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx(IconView.make, {
                                    size: "LG",
                                    color: "DarkGray",
                                    className: css.iconView
                                  }),
                              className: css.privateDownload,
                              href: props.privateDownload,
                              target: "_blank"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDelete.make, {
                                    size: "LG",
                                    color: "DarkGray",
                                    viewBoxSize: "20"
                                  }),
                              className: css.iconDelete,
                              onClick: (function (param) {
                                  onDelete();
                                })
                            })
                      ],
                      className: css.flexContainer
                    })
              ],
              className: css.documentRow
            });
}

var DocumentRow = {
  make: LocationBrochurePdf$DocumentRow
};

function LocationBrochurePdf(props) {
  var removeDocument = props.removeDocument;
  var $$document = props.document;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some($$document !== undefined ? JsxRuntime.jsx(LocationBrochurePdf$DocumentRow, {
                          privateDownload: props.privateDownload,
                          filename: $$document.filename,
                          byteSize: $$document.byteSize,
                          onDelete: (function () {
                              removeDocument($$document);
                            })
                        }, "recently-uploaded-document-" + $$document.filename) : null)
            });
}

var make = LocationBrochurePdf;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
