// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationBrochureEditForm from "./components/LocationBrochureEditForm.res.js";
import * as LocationsDashboardNavbar from "../../../locations/dashboard/index/components/navbar/LocationsDashboardNavbar.res.js";
import * as LocationBrochureEditScss from "./LocationBrochureEdit.scss";

var css = LocationBrochureEditScss;

function LocationBrochureEdit$default(props) {
  var locationBrochure = props.locationBrochure;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsDashboardNavbar.make, {
                      selectedCategory: "EditBrochure",
                      id: Caml_option.some(locationBrochure.id),
                      slug: locationBrochure.slug
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit Location Brochure"
                            }),
                        JsxRuntime.jsx(LocationBrochureEditForm.make, {
                              locationBrochure: locationBrochure,
                              providers: props.providers,
                              locations: props.locations
                            })
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = LocationBrochureEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
